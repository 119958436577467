import React, { useEffect, useState } from "react";

import { RedirectService } from '../../core/services/redirectService';
import { CookieService, Cookies } from "../../core/services/cookieService";
import Loading from "../Loading/Loading";
import SingleSignOn from "../SingleSignOn/SingleSignOn";

export function LoadingSpinner() {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff"
      }}
    >
      <Loading size="2x" />
    </div>
  );
}

function RedirectChecker({cookieService = CookieService.Instance(), redirectService = RedirectService.Instance(), SingleSignOnComponent = SingleSignOn}) {
  const [authChecked, setAuthChecked] = useState(false);
  const [accessTokenExpired, setAccessTokenExpired] = useState(false);
  const [userId, setUserId] = useState("");
  const [redirectDestination, setRedirectDestination] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [leadToken, setLeadToken] = useState("");

  useEffect(() => {
    (async () => {
      setAccessTokenExpired(!await cookieService.isSet(Cookies.USCCA_Access));
      const userId = (await cookieService.getDecoded(Cookies.USCCA_Access))?.userId;
      setUserId(userId);
      setAccessToken(await cookieService.getRaw(Cookies.USCCA_Access));
      setLeadToken(await cookieService.getRaw(Cookies.USCCA_Leads));

      if (accessTokenExpired || !userId) {
        await cookieService.deleteAuthCookies();
      }
      setAuthChecked(true);
    })();
  }, []);

  useEffect(() => {
    if (authChecked && !accessTokenExpired && !!userId) {
      (async () => {
        setRedirectDestination(await redirectService.resolveRedirectUrl());
      })();
    }
  }, [authChecked, accessTokenExpired, userId]);

  if (authChecked && (accessTokenExpired || !userId)) {
    return (
      <>
        <LoadingSpinner />
        <SingleSignOn action="logout" redirect_url={redirectService.resolveRedirectUrlForLogout()} />
      </>
    );
  } else {
    return (
      <>
        <LoadingSpinner />

        {!!redirectDestination && (
          <SingleSignOn
            action="login"
            redirect_url={redirectDestination}
            uscca_access={accessToken}
            uscca_lead_fields={leadToken}
          />
        )}
      </>
    );
  }
}

export default RedirectChecker;
