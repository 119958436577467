import React, { Component } from "react";
import { connect } from "react-redux";
import { withFormik, Form } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

import { updateUser, resetError as resetUserError } from "../../redux/modules/users";
import { verifyEmailAuthCode, resetError } from "../../redux/modules/verifications";
import InputGroup from "../InputGroup/InputGroup";
import AuthButton from "../AuthButton/AuthButton";
import Title from "../Title/Title";
import Loading from "../Loading/Loading";
import AuthAlert from "../AuthAlert/AuthAlert";
import PasswordResetSuccess from "../CompletedForms/PasswordResetSuccess";
import { CookieService } from "../../core/services/cookieService";
import { LoadingSpinner } from "../RedirectChecker/RedirectChecker";
import { DefaultErrorPage } from "../ErrorPage/defaultErrorPage";

const verificationErrorCodeMessages = {
  410: 'The code you\'ve entered has expired',
  422: 'The code you entered doesn\'t match the email',
  429: 'The code you have entered expired due to "too many invalid attempts."'
};
const defaultVerificationError = 'Something went wrong while confirming your verification code. Please try again later.';

export const getVerificationErrorMessage = (code) => verificationErrorCodeMessages[code] || defaultVerificationError;


class CreateNewPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPasswordResetSuccessful: false
    };
  }

  componentDidMount() {
    // when switching to this route user error go away
    this.props.resetError();
    this.props.resetUserError();
  }

  render() {
    const {
      email,
      userLoading,
      verificationsLoading,
      userStatusCode,
      verificationsStatusCode,
      errors,
      touched,
      values,
      handleKeyUp,
      handleChange,
      resetError,
      status
    } = this.props;
    const loading = verificationsLoading || userLoading;

    if (this.state.isPasswordResetSuccessful) {
      return <PasswordResetSuccess />;
    }

    if (userStatusCode === 200) {
      this.setState({ isPasswordResetSuccessful: true });
      return <LoadingSpinner />;
    } else if (!!userStatusCode) {
      return <DefaultErrorPage errorStatus={userStatusCode} />;
    }

    const verificationError = !!verificationsStatusCode && verificationsStatusCode >= 400
    const verificationErrorMessage = getVerificationErrorMessage(verificationsStatusCode);

    return (
      <>
        <Title name="Reset Password" />
        <p className="form-text">
          For your security, we've sent an authorization code to your email at {email}. If you've listed a mobile phone number, we have sent the authorization code via text. Enter the
          code below and create a new password. <b>This code is only valid for 1 hour.</b>
        </p>

        <Form>
          <InputGroup
            id="accountAuthorizationCode"
            name="accountAuthorizationCode"
            label="Authorization Code"
            type="tel"
            maxLength="6"
            value={values.accountAuthorizationCode}
            error={errors.accountAuthorizationCode}
            touched={touched.accountAuthorizationCode}
            onKeyUp={(e) => {
              e.preventDefault();
              handleKeyUp(resetError);
            }}
            onChange={handleChange}
            autoComplete="one-time-code"
          />
          {verificationError && (
            <AuthAlert
              message={verificationErrorMessage}
              link={
                verificationsStatusCode !== 422 && (
                  <>
                    Get new code{" "}
                    <Link to={`/forgot-password${window.location.search}`} className="link">
                      here.
                    </Link>
                  </>
                )
              }
            />
          )}
          <InputGroup
            id="createAccountPassword"
            name="password"
            label="Password"
            type="password"
            value={values.password}
            error={errors.password}
            touched={touched.password}
            onChange={handleChange}
            autoComplete="new-password"
            passwordUnmasked={status?.passwordUnmasked}
          />
          {touched.password && !errors.password && (
            <p className="mt-1 form-text">Use at least 8 characters</p>
          )}

          <InputGroup
            id="createAccountReEnterPassword"
            name="passwordConfirmation"
            label="Password Confirmation"
            type="password"
            value={values.passwordConfirmation}
            error={errors.passwordConfirmation}
            touched={touched.passwordConfirmation}
            onChange={handleChange}
            passwordUnmasked={status?.passwordUnmasked}
          />

          <AuthButton
            id="resetPasswordSave"
            color="btn-gold"
            text={loading ? <Loading /> : "Save Password"}
            disabled={loading}
          />
        </Form>
      </>
    );
  }
}

const CreateNewPasswordContainer = withFormik({
  mapPropsToValues: () => ({
    accountAuthorizationCode: "",
    password: "",
    passwordConfirmation: ""
  }),

  validationSchema: Yup.object().shape({
    accountAuthorizationCode: Yup.string()
      .min(6, "Authorization Code requires six digits")
      .matches(/[0-9.]/, "Only numbers are valid")
      .required("Authorization Code is required"),

    password: Yup.string()
      .min(8, "Password needs at least 8 characters.")
      .max(40, "Password needs less than 40 characters.")
      .required("Password is required"),

    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords don't match")
      .required("Password confirmation is required")
  }),

  validateOnBlur: false,

  handleSubmit: async (values, { props, setStatus }) => {
    // this set status is used to pass state from the handleSubmit function to the component
    setStatus({ passwordUnmasked: false });
    const { password, accountAuthorizationCode } = values;
    const verificationsId = props.match.params.verificationId || props.verificationId;
    try {
      const getToken = await props.verifyEmailAuthCode(
        verificationsId,
        accountAuthorizationCode,
        true
      );
      const token = getToken?.data?.attributes?.token;
      const ttl = getToken?.data?.attributes?.ttl;

      const userId = props.cookieService.decodeToken(token)?.userId || "";

      await props.updateUser(token, userId, password, true);
      await props.cookieService.setAccessToken(token, ttl);
    } catch (error) {
      console.log(error);
    }
    // this set status is used to reset the passwordUnmasked state
    setStatus({ passwordUnmasked: undefined });
  },

  displayName: "Create New Password"
})(CreateNewPassword);

CreateNewPasswordContainer.defaultProps = {
  cookieService: CookieService.Instance()
};

const mapStateToProps = ({
  users: {
    loading: userLoading,
    statusCode: userStatusCode,
    statusError: userStatusError,
    email
  },
  verifications: {
    verificationId,
    loading: verificationsLoading,
    statusCode: verificationsStatusCode,
    statusError: verificationsStatusError
  }
}) => {
  return {
    email,
    verificationId,
    userLoading,
    verificationsLoading,
    userStatusCode,
    verificationsStatusCode,
    userStatusError,
    verificationsStatusError
  };
};

export default connect(mapStateToProps, {
  updateUser,
  verifyEmailAuthCode,
  resetError,
  resetUserError
})(CreateNewPasswordContainer);
