import { EmbedService, Messages } from "../core/services/embedService";

export async function getAnalyticsProperties(deltaAnalytics = window["deltaAnalytics"], embedService = EmbedService.Instance()) {
  if (embedService.isEmbedded) {
    return (await embedService.messageParent(Messages.GetAnalyticsProperties)) || {};
  } else if (deltaAnalytics) {
    return deltaAnalytics.aggregateAvailableProperties() || {};
  } else {
    return {};
  }
}