// @ts-check

export function DoUntil(
  condition,
  interval,
  limit
) {
  return new Promise((resolve) => {
    let conditionMet = false;
    let elapsedTime = 0;
    const enabled = () => !conditionMet && (!limit || elapsedTime < limit);

    const executer = setInterval(async () => {
      elapsedTime += (interval || 1);
      if (enabled()) {
        conditionMet = condition();
      } else {
        clearInterval(executer);
        resolve(conditionMet);
      }
    }, interval);
  });
}
