//@ts-check
import React from 'react';
import { Link } from 'react-router-dom';

//css
import './SignInLinks.scss';

function SignInLinks({ className }) {
  return (
    <div className={`sign-in-links ${className ? className : ""}`}>
      <Link to={`/forgot-password${window.location.search}`} className="signInLinks" id='forgotPasswordLink'>
        Forgot Password
      </Link>
    </div>
  );
}

export default SignInLinks;
