// @ts-check
import React from "react";
import { Link } from "react-router-dom";

//css
import "./SignInLinks.scss";

function DefaultLinks() {
  return (
    <div className="sign-in-links mt-2">
      <Link to={`/${window.location.search}`} id="createAccountBackLink">
        {" "}
        &larr; Back to Sign In
      </Link>
    </div>
  );
}

export default DefaultLinks;
