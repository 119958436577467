// @ts-check
import Environments from '@delta-defense/client-environments';
import { Apis } from '@delta-defense/client-environments/environments/enums/apis';
import { CALL_API } from "../middleware/api";

//User Actions
const RESET_USER = "application/users/RESET_USER";
const ADD_EMAIL = "application/users/ADD_EMAIL";
const RESET_ERROR = "application/users/RESET_ERROR";
const USERS_REQUEST = "application/users/USERS_REQUEST";
const USERS_RESPONSE = "application/users/USERS_RESPONSE";
const USERS_AUTH_RESPONSE = "application/users/USERS_AUTH_RESPONSE";
const USERS_FAILURE = "application/users/USERS_FAILURE";

//Initial State
const initialState = {
  email: "",
  loading: false,
  statusCode: "",
  statusError: "",
  user: {},
  token: {}
};

//Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_USER: {
      return initialState;
    }
    case USERS_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case USERS_AUTH_RESPONSE: {
      const token = action.response?.included?.[0].attributes;
      const user = action.response?.data.attributes;
      return user && token?.token
        ? {
          ...state,
          loading: false,
          statusCode: action.status,
          statusError: "",
          user,
          token
        }
        : {
          ...state,
          loading: false,
          statusCode: 500,
          statusError: "No user or token returned!"
        };
    }
    case USERS_RESPONSE: {
      const user = action.response?.data.attributes;
      return user
        ? {
          ...state,
          loading: false,
          statusCode: action.status,
          statusError: "",
          user
        }
        : {
          ...state,
          loading: false,
          statusCode: 500,
          statusError: "No user returned!"
        };
    }
    case USERS_FAILURE: {
      return {
        ...state,
        loading: false,
        statusCode: action.status,
        statusError: action.error
      };
    }
    case ADD_EMAIL: {
      return {
        ...state,
        email: action.email
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        statusCode: "",
        statusError: ""
      };
    }

    default:
      return state;
  }
};

//Action Creators
export const resetUser = () => {
  return {
    type: RESET_USER
  };
};

export const addEmail = (email) => {
  return {
    type: ADD_EMAIL,
    email
  };
};

export const resetError = (error) => {
  return {
    type: RESET_ERROR,
    error
  };
};

//Async actions

export const createNewAccount = (user) => {
  return {
    [CALL_API]: {
      type: "POST",
      endpoint: `${Environments.getProxyEndpointForApi(Apis.Auth)}/users`,
      options: {
        ...user,
        meta: {
          ...window.deltaAnalytics?.aggregateAvailableProperties() || {}
        }
      },
      headers: {},
      actions: {
        request: USERS_REQUEST,
        success: USERS_AUTH_RESPONSE,
        failure: USERS_FAILURE
      }
    }
  };
};

export const updateUser = (authToken, id, password, shouldReturnBody = false) => {
  return {
    [CALL_API]: {
      type: "PATCH",
      endpoint: `${Environments.getProxyEndpointForApi(Apis.Auth)}/users/${id}`,
      options: {
        password
      },
      headers: {
        "x-auth-token": authToken
      },
      actions: {
        request: USERS_REQUEST,
        success: USERS_RESPONSE,
        failure: USERS_FAILURE
      },
      shouldReturnBody
    }
  };
};
