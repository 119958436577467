import React, { Component } from "react";

//css
import "./AuthAlert.scss";

class AuthAlert extends Component {
  componentDidMount() {
    // locate the first error on the page
    const error = this.props.mainDocument.querySelector("[data-input-error]");
    // find it's previous sibling
    const previousSibling = error.previousElementSibling;
    // then the sibling's input
    const input = previousSibling.querySelector("input");
    // and finally bring the input into focus
    input.focus();
  }

  render() {
    return (
      <div className="input--error" data-input-error>
        {this.props.message} {this.props.link}
      </div>
    );
  }
}

AuthAlert.defaultProps = {
  mainDocument: window.document
};

export default AuthAlert;
