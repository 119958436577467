import React from "react";
import Footer from "../Footer/Footer";
import { Icons } from "../../assets/Icons";
import { EmbedService } from "../../core/services/embedService";

function Layout({ children, embedService = EmbedService.Instance() }) {
  return embedService.isEmbedded ? (
    <div className="fixed-col main pt-3 pt-sm-5 mx-auto">{children}</div>
  ) : (
    <>
      <div className="fixed-col main pt-3 pt-sm-5 mx-auto">
        {Icons.USCCALogo}
        <div className="mt-5">{children}</div>
      </div>

      <Footer />
    </>
  );
}

export default Layout;
