import request from 'superagent';

const callApi = (type, endpoint, headers, options, shouldReturnBody = false) => {
  return request(type, endpoint)
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json')
    .set(headers)
    .send(options)
    .then((res) => {
      if (!res.ok) {
        return Promise.reject(res);
      }
      return res;
    });
};

export const CALL_API = Symbol('Call API');

export default (store) => (next) => (action) => {
  const callAPI = action[CALL_API];

  // So the middleware doesn't get applied to every single action
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  let { endpoint, type, headers, options, shouldReturnBody, actions } = callAPI;
  next({ type: actions.request });
  return callApi(type, endpoint, headers, options, shouldReturnBody).then(
    (response) => {
      next({
        response: response.body,
        type: actions.success,
        status: response.status
      });

      if (shouldReturnBody) {
        return response.body
      }
    },
    (error) => {
      next({
        error: error.message || 'There was an error.',
        type: actions.failure,
        status: error.status
      });

      if (shouldReturnBody) {
        return error
      }
    }
  );
};
