import jwt_decode from "jwt-decode";
import { CookieStorage } from "tsbase/Persistence/GenericStorage/CookieStorage";
import { EmbedService, Messages } from "./embedService";
import { EnvironmentService } from "./environmentService";

export const Cookies = {
  USCCA_Access: "uscca_access",
  USCCA_Session: "uscca_session",
  USCCA_Leads: "uscca_lead_fields",
  USCCA_SSO: "uscca_sso",
  Pantheon_Access: "SESSusccaaccess"
};

export class CookieService {
  static #instance = null;
  static Instance(
    embedService = EmbedService.Instance(),
    environmentService = EnvironmentService.Instance(),
    cookieJar = new CookieStorage(),
    jwtDecode = jwt_decode
  ) {
    return (
      CookieService.#instance ||
      (CookieService.#instance = new _CookieService(
        embedService,
        environmentService,
        cookieJar,
        jwtDecode
      ))
    );
  }
  static Destroy = () => (CookieService.#instance = null);
}

class _CookieService {
  #embedService;
  #environmentService;
  #cookieJar;
  #jwtDecode;

  constructor(embedService, environmentService, cookieJar, jwtDecode) {
    this.#embedService = embedService;
    this.#environmentService = environmentService;
    this.#cookieJar = cookieJar;
    this.#jwtDecode = jwtDecode;
  }

  async #getCookies(cookies) {
    if (this.#embedService.isEmbedded) {
      const parentCookies = await this.#embedService.messageParent(Messages.GetCookies, cookies);
      if (!parentCookies) {
        const emptyCookies = {};
        cookies.forEach((c) => {
          emptyCookies[c] = "";
        });
        return emptyCookies;
      }
      return parentCookies;
    } else {
      const cookiesWithValues = {};
      cookies.forEach((c) => {
        cookiesWithValues[c] = this.#cookieJar.GetValue(c)?.Value || "";
      });
      return cookiesWithValues;
    }
  }

  async #getCookie(cookie) {
    return (await this.#getCookies([cookie]))?.[cookie];
  }

  decodeToken(token) {
    let decodedToken;
    try {
      decodedToken = this.#jwtDecode(token);
    } catch (error) {
      if (!this.#environmentService.isProduction) {
        console.warn("Failed to decode jwt token: ", token, error);
      }
    }
    return decodedToken;
  }

  #isPastExpiration(expiration, nowFunc = Date.now) {
    if (typeof expiration !== "number") {
      return true;
    }
    const now = Math.round(nowFunc() / 1000);
    return now >= expiration;
  }

  #localSetCookie(name, value, expires) {
    const domain = this.#environmentService.domain;
    const isSecure = !this.#environmentService.isLocal;
    const options = {
      expires: new Date(expires),
      domain,
      secure: isSecure,
      path: "/"
    };
    this.#cookieJar.SetValue(name, value, options);
  }

  async #setCookies(cookies) {
    if (this.#embedService.isEmbedded) {
      await this.#embedService.messageParent(Messages.SetCookies, cookies);
    } else {
      cookies.forEach((c) => {
        this.#localSetCookie(c.name, c.value, c.expires);
      });
    }
  }

  async #setCookie(name, value, expires) {
    await this.#setCookies([{ name, value, expires }]);
  }

  async #deleteCookies(cookies) {
    if (this.#embedService.isEmbedded) {
      await this.#embedService.messageParent(Messages.DeleteCookies, cookies);
    } else {
      const domain = this.#environmentService.domain;
      cookies.forEach((c) => {
        this.#cookieJar.SetValue(c, "", { domain, path: "/", expires: new Date(0) });
        if (c == Cookies.Pantheon_Access) {
          this.#cookieJar.SetValue(c, "", { path: "/", expires: new Date(0) });
        }
      });
    }
  }

  async #deleteCookie(cookie) {
    await this.#deleteCookies([cookie]);
  }

  async deleteAuthCookies() {
    await this.#deleteCookies([
      Cookies.USCCA_Access,
      Cookies.USCCA_Session,
      Cookies.USCCA_SSO,
      Cookies.Pantheon_Access
    ]);
  }

  async getRaw(cookie) {
    return await this.#getCookie(cookie);
  }

  async getDecoded(cookie) {
    const encodedValue = await this.#getCookie(cookie);
    const decodedValue = this.decodeToken(encodedValue);
    return decodedValue;
  }

  async isSet(cookie) {
    const cookieValue = await this.#getCookie(cookie);
    if (!cookieValue) {
      return false;
    }

    switch (cookie) {
      case Cookies.USCCA_Access:
        const decodedAccessToken = this.decodeToken(cookieValue);
        if (!decodedAccessToken?.exp || this.#isPastExpiration(decodedAccessToken.exp)) {
          await this.deleteAuthCookies();
          return false;
        } else {
          return true;
        }
      default:
        return true;
    }
  }

  async setAccessToken(token, ttl) {
    let expires = ttl;
    if (typeof expires === "number") {
      expires = expires * 1000;
    }
    await this.#setCookies([
      {
        name: Cookies.USCCA_Access,
        value: token,
        expires
      },
      {
        name: Cookies.Pantheon_Access,
        value: token,
        expires
      }
    ]);
  }

  async setLeadFields(leadFields) {
    try {
      const expirationDate = new Date();
      expirationDate.setFullYear(expirationDate.getFullYear() + 1);
      const cookieValue = typeof leadFields === 'string' ? leadFields : btoa(JSON.stringify(leadFields));
      await this.#setCookie(Cookies.USCCA_Leads, cookieValue, expirationDate.getTime());
    } catch {
      // do
    }
  }
}
