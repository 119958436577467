import React, { Component, createRef } from "react";
import { EnvironmentService } from "../../core/services/environmentService";
import { EmbedService, Messages } from "../../core/services/embedService";
import Loading from "../Loading/Loading";
import "./SingleSignOn.scss";

class SingleSignOn extends Component {
	state = {
		redirect_url: this.props.redirect_url
	};
	submitButton = createRef();

	componentDidMount() {
		if (this.props.embedService.isEmbedded) {
			this.props.embedService.messageParent(Messages.Redirect, {
				action: this.props.action,
				redirect_url: this.props.redirect_url
			});
		} else {
			(async () => {
				const params = new URLSearchParams(this.props.locationSearch);
				if (params.has('verification')) {
					try {
						const verificationCodeResponse = await fetch('https://member-verification.uscca.workers.dev/get-verification-code', {
							method: 'POST',
							headers: {
								'X-Auth-Token': this.props.uscca_access
							}
						});
						const verificationCodeJson = await verificationCodeResponse.json();
						const redirectUrlWithVerificationCode = new URL(this.props.redirect_url);
						redirectUrlWithVerificationCode.searchParams.delete('verification');
						redirectUrlWithVerificationCode.searchParams.set('verificationCode', verificationCodeJson.code);
						this.setState({
							redirect_url: redirectUrlWithVerificationCode.href
						});
					} catch (error) {
						console.error('Unable to request verification code', error);
					}
				}

				this.submitButton.current?.click();
			})();
		}
	}

	render() {
		return (
			<>
				<div
					style={{
						height: '100vh',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						color: '#fff'
					}}
				>
					<Loading size="2x" />
				</div>
				<form className="sso-form" method="POST" action={this.props.environmentService.ddComUrl + '/sso'}>
					<input type="text" name="action" value={this.props.action} />
					<input type="text" name="redirect_url" value={this.state.redirect_url} />
					<input type="text" name="uscca_access" value={this.props.uscca_access} />
					<input type="text" name="uscca_lead_fields" value={this.props.uscca_lead_fields} />
					<input ref={this.submitButton} type="submit" value="Submit" />
				</form>
			</>
		);
	}
}

SingleSignOn.defaultProps = {
	environmentService: EnvironmentService.Instance(),
	embedService: EmbedService.Instance(),
	locationSearch: window.location.search
};

export default SingleSignOn;
