// @ts-check
import React, { Component } from "react";
import Title from "../Title/Title";
import Spinner from "../Spinner/Spinner";
import SingleSignOn from "../SingleSignOn/SingleSignOn";
import { RedirectService } from "../../core/services/redirectService";
import { CookieService } from "../../core/services/cookieService";
import "./LogoutPage.scss";

class LogoutPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false
    };
  }

  componentDidMount() {
    (async () => {
      await this.props.cookieService.deleteAuthCookies();
      this.setState({ ready: true });
    })();
  }

  render() {
    return (
      <>
        <Title name="Logging Out..." textPosition="auth-title--center" />
        <hr className="logout__divider" />

        <Spinner />
        <p className="logout__message">
          Please wait...
          <br />
          You're being logged out and you'll be redirected back to the website in a moment.
        </p>

        {this.state.ready && (
          <this.props.SingleSignOnComponent action="logout" redirect_url={this.props.redirectService.resolveRedirectUrlForLogout()} />
        )}
      </>
    );
  }
}
LogoutPage.defaultProps = {
  redirectService: RedirectService.Instance(),
  cookieService: CookieService.Instance(),
  SingleSignOnComponent: SingleSignOn
};

export default LogoutPage;
