import { loadSegment as loadSegmentFromLib } from '@delta-defense/frontend-analytics/loadSegment/loadSegment';
import { EnvironmentService } from '../core/services/environmentService';
import { DoUntil } from './doUntil';

export function loadSegment() {
  const environmentService = EnvironmentService.Instance();
  loadSegmentFromLib(environmentService.isProduction ? "cLX8lOVvZz5oM9BrBCCNLH7I6s47zJKk" : "veUGgc2nojg74W6R7YZQGCibCCEVo8X6");

  DoUntil(() => {
    const deltaAnalytics = window["deltaAnalytics"];
    if (deltaAnalytics) {
      deltaAnalytics.page();
    }
    return !!deltaAnalytics;
  }, 100);
}

export function identifyUser(
  usccaUser,
  mainWindow = window
) {
  const analytics = mainWindow["deltaAnalytics"];
  if (analytics && typeof analytics.identify === "function") {
    if (usccaUser) {
      const userData = {
        firstName: usccaUser.name || undefined,
        lastName: usccaUser.surname || undefined,
        email: usccaUser.email || undefined,
        crmId: usccaUser.crmId || undefined
      };
      usccaUser.group_status && (userData.group_status = usccaUser.group_status);
      analytics.identify(usccaUser.userId, userData);
    }
  }
}

export function handleRouteChange() {
  const analytics = window["deltaAnalytics"];
  if (analytics && typeof analytics.page === "function") {
    analytics.page();
  }
}
