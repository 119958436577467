import { getLocationSearchWithoutRedirectParam } from "../../utilities/getLocationSearchWithoutRedirectParam";
import {
  getGlobalRedirectParam,
  getMemberRedirectParam,
  getNonMemberRedirectParam
} from "../../utilities/getRedirectToParam";
import { EnvironmentService } from "./environmentService";
import { CookieService, Cookies } from "./cookieService";

const productionWhitelist = [
  "usconcealedcarry.com",
  "deltadefense.com"
];

const developmentWhitelist = [
  "usccadev.com",
  "deltadefensedev.com",
  "localhost",
  "pages.dev"
];

export class RedirectService {
  static #instance = null;
  static Instance(
    environmentService = EnvironmentService.Instance(),
    cookieService = CookieService.Instance()
  ) {
    return (
      RedirectService.#instance ||
      (RedirectService.#instance = new _RedirectService(
        environmentService,
        cookieService
      ))
    );
  }
  static Destroy = () => (RedirectService.#instance = null);
}

class _RedirectService {
  #environmentService;
  #cookieService;

  constructor(environmentService, cookieService) {
    this.#environmentService = environmentService;
    this.#cookieService = cookieService;
  }

  #isAllowedRedirectUrl(redirectUrl) {
    const regexMatch = redirectUrl.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/i);
    const redirectDomain = regexMatch ? regexMatch[0] : "";
    const whitelist = this.#environmentService.isProduction ? productionWhitelist : [...productionWhitelist, ...developmentWhitelist];

    return whitelist.some((allowedDomain) => redirectDomain.endsWith(allowedDomain))
  }

  #getDefaultRedirectForEnvironment(locationSearch) {
    const locationSearchToUse = getLocationSearchWithoutRedirectParam(locationSearch);
    if (this.#environmentService.isLocal) {
      return `http://localhost:3001${locationSearchToUse}`;
    }

    if (this.#environmentService.isDev) {
      return `https://my.dev.usccadev.com${locationSearchToUse}`;
    }

    if (this.#environmentService.isStaging) {
      return `https://my.usccadev.com${locationSearchToUse}`;
    }

    return `https://my.usconcealedcarry.com${locationSearchToUse}`;
  }

  async resolveRedirectUrl(locationSearch = window.location.search, fetchRef = window.fetch) {
    const globalRedirect = getGlobalRedirectParam(locationSearch);
    const memberRedirect = getMemberRedirectParam(locationSearch);
    const nonMemberRedirect = getNonMemberRedirectParam(locationSearch);
    let redirectToUse = globalRedirect;

    const accessToken = await this.#cookieService.getRaw(Cookies.USCCA_Access);
    if ((nonMemberRedirect || memberRedirect) && accessToken) {
      const meResult = await fetchRef("/mesh", {
        method: "POST",
        body: JSON.stringify({ query: "{ me { subscriptionLevel } }" }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Auth-Token": accessToken
        }
      });
      if (meResult.ok) {
        const json = await meResult.json();
        if (json?.data?.me?.subscriptionLevel && json.data.me.subscriptionLevel != "ccm") {
          redirectToUse = memberRedirect || globalRedirect;
        } else {
          redirectToUse = nonMemberRedirect || globalRedirect;
        }
      }
    }

    if (!this.#isAllowedRedirectUrl(redirectToUse)) {
      redirectToUse = this.#getDefaultRedirectForEnvironment(locationSearch);
    }

    return redirectToUse;
  }

  #getDefaultLogoutRedirectForEnvironment() {
    if (this.#environmentService.isLocal) {
      return `https://uscca.local`;
    }

    if (this.#environmentService.isDev) {
      return `https://www.dev.usccadev.com`;
    }

    if (this.#environmentService.isStaging) {
      return `https://www.usccadev.com`;
    }

    return `https://www.usconcealedcarry.com`;
  }

  resolveRedirectUrlForLogout(locationSearch = window.location.search) {
    let redirectToUse = getGlobalRedirectParam(locationSearch);

    if (!this.#isAllowedRedirectUrl(redirectToUse)) {
      redirectToUse = this.#getDefaultLogoutRedirectForEnvironment();
    }

    return redirectToUse;
  }
}
