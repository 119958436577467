// @ts-check
import {
  redirectToParam,
  alternateRedirectToParam,
  memberRedirectToParam,
  nonMemberRedirectToParam
} from "../constants";

export const getGlobalRedirectParam = (
  locationSearch = window.location.search
) => {
  const params = new URLSearchParams(locationSearch);
  const redirectParam = params.get(redirectToParam) || params.get(alternateRedirectToParam) || "";

  return sanitizeReturn(redirectParam);
}

export const getMemberRedirectParam = (
  locationSearch = window.location.search
) => {
  const params = new URLSearchParams(locationSearch);
  const redirectParam = params.get(memberRedirectToParam) || "";

  return sanitizeReturn(redirectParam);
}

export const getNonMemberRedirectParam = (
  locationSearch = window.location.search
) => {
  const params = new URLSearchParams(locationSearch);
  const redirectParam = params.get(nonMemberRedirectToParam) || "";

  return sanitizeReturn(redirectParam);
}

const sanitizeReturn = (url) => {
  let returnVal = url;

  if (returnVal.endsWith("?")) {
    returnVal = returnVal.slice(0, returnVal.length - 1);
  }

  return returnVal;
}
