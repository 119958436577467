import React from "react";
import { Icons } from "../../assets/Icons";
import './defaultErrorPage.scss'

export const DefaultErrorPage = ({errorStatus}) => {
  function refreshPage() {
    window.location.reload();
  }
  return (
    <div className='container'>
      <div>
        <button onClick={() => {refreshPage()}} className='backButton'>{Icons.backArrow}<span className="back">Back</span></button>
      </div>
      <h1>We're sorry.</h1>
      <div className='svg-wrap'>
        {Icons.ComputerError}
      </div>
      <h1>{errorStatus} Error</h1>
      <p>Our team is addressing this problem. Please try again shortly or call us at <span className='nobr'><b><a className="phoneLink" href="tel:1-800-674-9779">1-800-674-9779</a></b></span> for further assistance.</p>
    </div>
  )
}
