import React, { Component } from "react";
import { connect } from "react-redux";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";

import { userSignIn, resetTokens, resetError } from "../../redux/modules/tokens";
import { CookieService } from "../../core/services/cookieService";
import { LoadingSpinner } from "../RedirectChecker/RedirectChecker";
import { DefaultErrorPage } from "../ErrorPage/defaultErrorPage";
import InputGroup from "../InputGroup/InputGroup";
import AuthButton from "../AuthButton/AuthButton";
import SignInLinks from "../NavigationLinks/SignInLinks";
import Title from "../Title/Title";
import Question from "../AdditionalInfo/Question";
import AuthAlert from "../AuthAlert/AuthAlert";
import Loading from "../Loading/Loading";
import "../Forms/Forms.scss";

export class SignInPassword extends Component {
  componentDidMount() {
    if (!this.props.token) {
      this.props.resetTokens();
    } else {
      this.emailInput.focus();
      this.emailInput.click();
    }

    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has("email")) {
      this.props.values.email = queryParams.get("email");
      this.setState({ values: this.props.values });
    }
  }

  setCookie = async () => {
    const { token, ttl } = this.props.token;
    await this.props.cookieService.setAccessToken(token, ttl);
  };

  handleCheckboxChange = (e) => {
    this.props.setFieldValue("remember", e.target.checked);
  };

  postLoginAction = async (
    usccaAccessToken,
    history,
    mainWindow = window,
    setCookieFunc = this.setCookie
  ) => {
    await setCookieFunc();
    history.push(`/redirect${mainWindow.location.search}`);
  };

  render() {
    const {
      loading,
      errors,
      touched,
      history,
      statusCode,
      values,
      handleKeyUp,
      resetError,
      handleChange,
      status
    } = this.props;
    const usccaAccessToken = this.props.token.token;
    const isVerificationRequest = new URLSearchParams(window.location.search).has('verification');

    const handledErrorCodes = [404, 422];
    if (statusCode === 200) {
      this.postLoginAction(usccaAccessToken, history);
      return <LoadingSpinner />;
    } else if (!!statusCode && !handledErrorCodes.includes(statusCode)) {
      return <DefaultErrorPage errorStatus={statusCode} />;
    }

    const onRememberMeClicked = () => {
      const checkbox = window.document.getElementById("signInRememberMeCheckBox");
      if (checkbox) {
        checkbox.click();
      }
    };

    return (
      <>
        <Title name={isVerificationRequest ? "Member Verification" : "Log In"} />
        {isVerificationRequest && <p className="description">Log in to verify your USCCA membership status</p>}
        <Form>
          <InputGroup
            id="signinEmail"
            name="email"
            label="Email"
            type="email"
            innerRef={(ref) => (this.emailInput = ref)}
            onKeyUp={(e) => {
              e.preventDefault();
              handleKeyUp(resetError);
            }}
            error={errors.email}
            touched={touched.email}
            value={this.props.values.email}
            onChange={handleChange}
            autoComplete="email"
          />
          {!loading && statusCode === 404 && (
            <AuthAlert message="There is no account for the email you entered." />
          )}

          <InputGroup
            id="signinPassword"
            name="password"
            label="Password"
            type="password"
            innerRef={(ref) => (this.passwordInput = ref)}
            onKeyUp={(e) => {
              e.preventDefault();
              handleKeyUp(resetError);
            }}
            error={errors.password}
            touched={touched.password}
            value={this.props.values.password}
            onChange={handleChange}
            autoComplete="current-password"
            passwordUnmasked={status?.passwordUnmasked}
          />
          {!loading && statusCode === 422 && (
            <AuthAlert message="There was an error when entering your password." />
          )}

          <div className="remember-section">
            <div className="form-check">
              <Field
                id="remember"
                onChange={this.handleCheckboxChange}
                checked={values.remember}
                className="form-check-input"
                type="checkbox"
                name="remember"
                label="remember"
              />
              <label
                htmlFor="remember"
                className="form-check-label remember-label"
                onClick={onRememberMeClicked}
              >
                Remember me
              </label>
            </div>

            <SignInLinks />
          </div>

          <AuthButton
            id="signInSignInButton"
            color="btn-gold"
            text={loading ? <Loading /> : "Log In Now"}
            disabled={loading}
          />
        </Form>

        <Question
          headline="Don't have an account?"
          link={`/register${window.location.search}`}
          text="Sign Up Today"
          id="signUpTodayButton"
        />
      </>
    );
  }
}

SignInPassword.defaultProps = {
  cookieService: CookieService.Instance()
};

const SignInPasswordContainer = withFormik({
  mapPropsToValues: () => ({
    email: "",
    password: "",
    remember: true
  }),

  validationSchema: Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .min(2, "Password needs more than 1 characters.")
      .max(40, "Password needs less than 40 characters.")
      .required("Password is required")
  }),

  validateOnBlur: false,
  handleSubmit: (values, { props, setStatus }) => {
    // this set status is used to pass state from the handleSubmit function to the component
    setStatus({ passwordUnmasked: false });
    const { email, password, remember } = values;
    const yearFromToday = dayjs().add(1, "year").format("YYYY-MM-DD") + " 00:00:00";
    const daysFromToday = dayjs().add(7, "days").format("YYYY-MM-DD") + " 00:00:00";
    const ttl = remember ? yearFromToday : daysFromToday;
    props.userSignIn(email, password, ttl);

    // this set status is used to reset the passwordUnmasked state
    setStatus({ passwordUnmasked: undefined });
  },

  displayName: "SignIn Password"
})(SignInPassword);

const mapStateToProps = ({
  tokens: { token, loading, statusError, statusCode },
  users: { email }
}) => {
  return {
    email,
    token,
    loading,
    statusError,
    statusCode
  };
};

export default connect(mapStateToProps, { userSignIn, resetTokens, resetError })(
  SignInPasswordContainer
);
