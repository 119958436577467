import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const Loading = (props) => {
  library.add(faSpinner);
  return <FontAwesomeIcon icon="spinner" spin size={props.size} />;
};

export default Loading;
