// @ts-check
import { redirectToParam, alternateRedirectToParam, memberRedirectToParam, nonMemberRedirectToParam, ignoreEmbedParam } from '../constants';

export const getLocationSearchWithoutRedirectParam = (
  locationSearch = window.location.search
) => {
  const params = new URLSearchParams(locationSearch);
  params.delete(redirectToParam);
  params.delete(alternateRedirectToParam);
  params.delete(memberRedirectToParam);
  params.delete(nonMemberRedirectToParam);
  params.delete(ignoreEmbedParam);
  return params.toString() ? `?${params.toString()}` : '';
};
