// @ts-check
import React, { useEffect, useState } from "react";

import { EmbedService, Messages } from "../../core/services/embedService";
import { CookieService, Cookies } from "../../core/services/cookieService";
import { RedirectService } from "../../core/services/redirectService";
import { EnvironmentService } from "../../core/services/environmentService";
import { LoadingSpinner } from "../RedirectChecker/RedirectChecker";
import Title from "../Title/Title";
import GetMobileApp from "../AdditionalInfo/GetMobileApp";
import AuthButton from "../AuthButton/AuthButton";

import "../CompletedForms/CompletedForms.scss";

const AccountCreated = ({
  embedService = EmbedService.Instance(),
  cookieService = CookieService.Instance(),
  redirectService = RedirectService.Instance(),
  environmentService = EnvironmentService.Instance()
}) => {
  const [redirectDestination, setRedirectDestination] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [leadToken, setLeadToken] = useState("");

  useEffect(() => {
    (async () => {
      const redirectUrl = await redirectService.resolveRedirectUrl();
      setAccessToken(await cookieService.getRaw(Cookies.USCCA_Access));
      setLeadToken(await cookieService.getRaw(Cookies.USCCA_Leads));
      setRedirectDestination(redirectUrl);
    })();
  }, []);

  const isAcademyRedirect =
    redirectDestination.includes("academy") || redirectDestination.includes("training");

  return !!redirectDestination ? (
    <>
      <Title name="SUCCESS!" />

      <p className="form-text">
        We have created your new USCCA account. Click the button below to access{" "}
        {isAcademyRedirect ? "our Protector Academy to" : "your USCCA Dashboard and"} preview the
        exceptional training content available to our Members.
      </p>

      {embedService.isEmbedded ? (
        <AuthButton
          id="myAccountButton"
          color="btn-gold"
          text="Continue"
          onClick={() => {
            embedService.messageParent(Messages.Redirect, {
              action: "login",
              redirect_url: redirectDestination
            });
          }}
        />
      ) : (
        <>
          <form className="secret-form" method="POST" action={environmentService.ddComUrl + "/sso"}>
            <input type="text" name="action" value="login" />
            <input type="text" name="redirect_url" value={redirectDestination} />
            <input type="text" name="uscca_access" value={accessToken} />
            <input type="text" name="uscca_lead_fields" value={leadToken} />
            <AuthButton id="myAccountButton" color="btn-gold" text="Continue" />
          </form>
          <GetMobileApp />
        </>
      )}
    </>
  ) : (
    <LoadingSpinner />
  );
};

export default AccountCreated;
