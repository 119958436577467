// @ts-check
import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { loadDataLayer } from '@delta-defense/frontend-analytics/loadDataLayer/loadDataLayer';

import { CookieService, Cookies } from "../../core/services/cookieService";
import { loadSegment, handleRouteChange } from "../../utilities/loadSegment";
import SignIn from "../Forms/SignIn";
import ForgotPassword from "../Forms/ForgotPassword";
import CreateNewPassword from "../Forms/CreateNewPassword";
import CreateAccount from "../Forms/CreateAccount";
import LogoutPage from "../LogoutPage/LogoutPage";
import FourOFour from "../ErrorPage/404";
import RedirectChecker from "../RedirectChecker/RedirectChecker";
import Layout from "../Layout/Layout";

class FormContainer extends Component {
  componentDidMount() {
    loadDataLayer();
    loadSegment();
    this.props.history.listen(handleRouteChange);

    //check if cookies exist
    (async () => {
      const path = window.location.pathname;
      const accessTokenSet = await this.props.cookieService.isSet(Cookies.USCCA_Access);
      if (accessTokenSet && !path.includes("logout") && !path.includes("redirect")) {
        this.props.history.push(`/redirect${window.location.search}`);
      }
    })();
  }

  handleKeyUp = (resetErrorHandler) => {
    // and if the following element exists remove the error message
    if (document.querySelector("[data-input-error]") != null) {
      resetErrorHandler();
    }
  };

  render() {
    return (
      <Layout>
        <Switch>
          <Route
            path="/"
            render={(props) => <SignIn handleKeyUp={this.handleKeyUp} {...props} />}
            exact={true}
          />

          <Route
            path="/forgot-password"
            render={() => <ForgotPassword handleKeyUp={this.handleKeyUp} />}
            exact={true}
          />

          <Route
            path="/forgot-password/:verificationId"
            render={(props) => <CreateNewPassword handleKeyUp={this.handleKeyUp} {...props} />}
          />

          <Route
            path="/register"
            render={() => <CreateAccount handleKeyUp={this.handleKeyUp} />}
            exact={true}
          />

          <Route path="/logout" component={LogoutPage} exact={true} />

          <Route
            path="/redirect"
            render={(props) => <RedirectChecker {...props} />}
            exact={false}
          />

          <Route path="*" component={FourOFour} />
        </Switch>
      </Layout>
    );
  }
}

FormContainer.defaultProps = {
  cookieService: CookieService.Instance()
};

export default withRouter(FormContainer);
