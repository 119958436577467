import Environments from "@delta-defense/client-environments";

export const Domains = {
  Local: "localhost",
  Dev: ".dev.usccadev.com",
  Staging: ".usccadev.com",
  Production: ".usconcealedcarry.com"
};

export const DDComUrls = {
  Local: "http://0.0.0.0:4000",
  Dev: "https://www.dev.deltadefensedev.com",
  Staging: "https://www.deltadefensedev.com",
  Production: "https://www.deltadefense.com"
};

export class EnvironmentService {
  static #instance = null;
  static Instance(environmentLibrary = Environments) {
    return this.instance || (this.instance = new _EnvironmentService(environmentLibrary));
  }
  static Destroy = () => (EnvironmentService.instance = null);
}

class _EnvironmentService {
  #environmentLibrary;

  constructor(environmentLibrary) {
    this.#environmentLibrary = environmentLibrary;
  }

  get isLocal() {
    return this.#environmentLibrary.isLocal();
  }
  get isDev() {
    return this.#environmentLibrary.isDev();
  }
  get isStaging() {
    return this.#environmentLibrary.isStaging();
  }
  get isProduction() {
    return this.#environmentLibrary.isProduction();
  }

  get domain() {
    if (this.isLocal) {
      return Domains.Local;
    } else if (this.isDev) {
      return Domains.Dev;
    } else if (this.isStaging) {
      return Domains.Staging;
    } else {
      return Domains.Production;
    }
  }

  get ddComUrl() {
    if (this.isLocal) {
      return DDComUrls.Local;
    } else if (this.isDev) {
      return DDComUrls.Dev;
    } else if (this.isStaging) {
      return DDComUrls.Staging;
    } else {
      return DDComUrls.Production;
    }
  }
}
