import { ignoreEmbedParam } from "../../constants";

export const Messages = {
  GetCookies: "GET_COOKIES",
  SetCookies: "SET_COOKIES",
  DeleteCookies: "DELETE_COOKIES",
  GetAnalyticsProperties: "GET_ANALYTICS_PROPERTIES",
  Redirect: "REDIRECT",
  Acknowledgement: "ACKNOWLEDGED"
};

export class EmbedService {
  static #instance = null;
  static Instance(appWindow = window) {
    return EmbedService.#instance || (EmbedService.#instance = new _EmbedService(appWindow));
  }
  static Destroy = () => (EmbedService.#instance = null);
}

class _EmbedService {
  #appWindow;
  #isEmbedded;

  constructor(appWindow) {
    this.#appWindow = appWindow;
    this.#isEmbedded = !!appWindow?.parent && appWindow.parent != appWindow;
    if (this.#isEmbedded) {
      const params = new URLSearchParams(appWindow.location.search);
      const ignoreEmbedParamValue = params.get(ignoreEmbedParam) || "";
      if (!!ignoreEmbedParamValue) {
        this.#isEmbedded = false;
      }
    }
  }

  static messageID = 0;

  get isEmbedded() {
    return this.#isEmbedded;
  }

  messageParent(msg, body, maxTimeout=1000) {
    if (!this.isEmbedded) {
      return;
    }
    const parent = this.#appWindow?.parent;
    const message = {
      message: msg,
      body: body,
      id: _EmbedService.messageID++
    };

    const promise = new Promise((r) => {
      let resolved = false;
      const timeout = setTimeout(() => {
        if (!resolved) {
          resolved = true;
          r();
        }
      }, maxTimeout);
      const listener = (event) => {
        if (event?.data?.message == Messages.Acknowledgement && event.data.id == message.id) {
          this.#appWindow.removeEventListener("message", listener);
          if(!resolved) {
            resolved = true;
            clearTimeout(timeout);
            r(event.data.body);
          }
        }
      };
      this.#appWindow.addEventListener("message", listener);
    });
    parent.postMessage(message, "*");
    return promise;
  }
}
