import React from "react";
import { Link } from "react-router-dom";

import "../AdditionalInfo/AdditionalInfo.scss";

function Question({ headline, link, text, id }) {
  return (
    <div className="d-flex align-items-center additional-info">
      <h4 className="my-0 mr-2 headline--no-account">{headline}</h4>

      <Link to={link} className="link" id={id}>
        {text}
      </Link>
    </div>
  );
}

export default Question;
