import React from 'react';

//css
import './AuthButton.scss';

const AuthButton = (props) => (
  <div className="uscca-auth-btn" style={props.style}>
    <button
      type={props.type ? props.type : 'submit'}
      id={props.id}
      className={`btn ${props.color}`}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.text}
    </button>
  </div>
);

export default AuthButton;
